import React, { useState, useEffect } from "react";
import { AppContext } from "./AppContext";

const AppProvider = ({ children }) => {
  const [country, setCountry] = useState(null);

  const getCountryFromHostname = (hostname) => {
    const parts = hostname.split('.');
    const tld = parts.slice(-1)[0];

    const tldToCountry = {
      'ar': 'Argentina',
      'cl': 'Chile',
      'uy': 'Uruguay',
      'bo': 'Bolivia',
      'py': 'Paraguay'
    };

    return tldToCountry[tld] || 'Argentina';
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedCountry = localStorage.getItem("country");
      let foundCountry = savedCountry;

      if (!foundCountry) {
        const location = window.location;

        if (location.hostname === 'localhost') {
          foundCountry = 'Argentina';
        } else {
          foundCountry = getCountryFromHostname(location.hostname);
        }

        localStorage.setItem("country", foundCountry);
      }

      setCountry(foundCountry);
    }
  }, []);

  const contextValue = {
    country,
    setCountry,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
