exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cafetera-js": () => import("./../../../src/pages/cafetera.js" /* webpackChunkName: "component---src-pages-cafetera-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-detalle-[slug]-js": () => import("./../../../src/pages/detalle/[slug].js" /* webpackChunkName: "component---src-pages-detalle-[slug]-js" */),
  "component---src-pages-etermo-js": () => import("./../../../src/pages/etermo.js" /* webpackChunkName: "component---src-pages-etermo-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novedades-[id]-js": () => import("./../../../src/pages/novedades/[id].js" /* webpackChunkName: "component---src-pages-novedades-[id]-js" */),
  "component---src-pages-novedades-js": () => import("./../../../src/pages/novedades.js" /* webpackChunkName: "component---src-pages-novedades-js" */),
  "component---src-pages-productos-[category]-js": () => import("./../../../src/pages/productos/[category].js" /* webpackChunkName: "component---src-pages-productos-[category]-js" */),
  "component---src-pages-recetas-[slug]-js": () => import("./../../../src/pages/recetas/[slug].js" /* webpackChunkName: "component---src-pages-recetas-[slug]-js" */),
  "component---src-pages-recetas-js": () => import("./../../../src/pages/recetas.js" /* webpackChunkName: "component---src-pages-recetas-js" */),
  "component---src-pages-resultado-de-busqueda-js": () => import("./../../../src/pages/resultado-de-busqueda.js" /* webpackChunkName: "component---src-pages-resultado-de-busqueda-js" */),
  "component---src-pages-servicio-tecnico-js": () => import("./../../../src/pages/servicio-tecnico.js" /* webpackChunkName: "component---src-pages-servicio-tecnico-js" */),
  "component---src-pages-ventilador-js": () => import("./../../../src/pages/ventilador.js" /* webpackChunkName: "component---src-pages-ventilador-js" */),
  "component---src-pages-vitroconvector-js": () => import("./../../../src/pages/vitroconvector.js" /* webpackChunkName: "component---src-pages-vitroconvector-js" */)
}

